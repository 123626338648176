import React from "react"
import { css } from "@emotion/core"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Artwork from "@images/to-the-moon.svg"
import { SEO } from "@components/seo"
import { Layout } from "@layouts/default"
import { LinkButton } from "@components/link-button/link-button"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" description="" />
    <Container>
      <Row>
        <Col
          md={{ span: 5, offset: 1 }}
          css={styles.centerContent}
          className="mt-5 mb-5"
        >
          <h2 css={styles.title} className="mb-4">
            Oops! The page you were looking for doesn&apos;t exist.
          </h2>

          <p className="mb-4">
            You may have mistyped the address or the page may have been moved.
          </p>

          <LinkButton type="fill" label="Take me home" url="/" />
        </Col>

        <Col
          md={{ span: 5, offset: 1 }}
          css={styles.centerContent}
          className="mt-5 mb-5"
        >
          <Image src={Artwork} fluid />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage

const styles = {
  title: css`
    font-size: 40px;
    font-weight: bold;
  `,
  centerContent: css`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  `,
}
